@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&family=Rammetto+One&display=swap');

body {
  margin: 0;
  font-family: "Oxanium", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0E0E0E;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.esggSK:disabled {
  cursor: not-allowed !important;
  color: rgba(249, 246, 246, 0.18) !important;
  fill: rgba(248, 242, 242, 0.18) !important;
}

.esggSK {
  color: rgb(255, 255, 255) !important;
  fill: rgb(255, 255, 255) !important;
}

/* 
.hZUxNm , .sc-cBYhjr, .djUvQc div:first-child, .rdt_TableCell {
  overflow: visible !important;
  white-space: pre-wrap !important;
}
*/

.rdt_TableCell {
  background: transparent !important;
}

.my-games .hGkspH,
.my-games .rdt_TableCell,
.my-games .rdt_TableCol {
  border-right: 1px solid rgba(85, 85, 85, .239) !important;
  padding: 8px 16px !important;
}


.main-dot-bg {
  border-color: rgba(255, 255, 255, 0.16);
  border-width: 1px;
  border-radius: 0.5rem;
  background-color: #222222;
}

.main-border {
  border-color: rgba(255, 255, 255, 0.16);
  border-width: 1px;
}

.react-datepicker-wrapper {
  width: 100%;
}


.d_processing {
  position: absolute;
  top: 38%;
  left: 0%;
  width: 100%;
  margin-left: 0%;
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.2em;
  background-color: #222222;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  border-color: #ddd;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 700;
}

.RammettoOne {
  font-family: 'Rammetto One', cursive;
}

.kryIMW,
.rdt_TableCol_Sortable {
  font-weight: bold !important;
  overflow: visible;
}

.rdt_TableCol_Sortable {
  font-size: 16px !important;
}

.hGkspH,
.rdt_TableCol,
.rdt_TableCell {
  border-right: 1px solid #5555553d !important;
}

.jlPZUL,
.ofEne {
  overflow: visible !important;
  white-space: normal !important;
  display: flex !important;
  justify-content: center !important;
}

.kryIMW,
.iedScr {
  font-size: 15px !important;
  font-weight: 700 !important;
  overflow: visible;
}

.ghLWkf div:first-child,
.jJhCqB div:first-child {
  white-space: inherit !important;
}

.erkbiK div:first-child,
.lnOUep,
.cJTPDY div:first-child,
.sc-eTNRI,
.rdt_TableCell div:first-child,
.hZUxNm {
  white-space: pre-wrap !important;
  overflow: visible !important;
}

.rdt_TableCell {
  font-size: 14px !important;
  font-weight: 400;
}

.rdt_TableHeadRow {
  background-color: #d4c7c7 !important;
}

.rdt_TableCol {
  font-weight: 900 !important;
  font-size: 0.9rem !important;
}


/*  */
.css-e1wkzd {
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('https://ik.imagekit.io/luckycharm/assets/wheel_bg.png');
  -webkit-background-size: cover;
  background-size: cover;
}

.css-1qfio2 {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
  font-weight: var(--chakra-fontWeights-bold);
  color: #FFAA00;
  z-index: 999;
  font-family: 'Poppins' !important;
  display: block;
}


@media screen and (min-width: 48em) {
  .css-1qfio2 {
    font-size: 3rem;
  }
}


.orange-blink {
  animation: orange-blink 2s infinite;
}

@keyframes orange-blink {
  0% {
    text-shadow: 0 0 2px #000, 0 0 5px #FFAA00, 0 0 15px #FFAA00;
  }

  33% {
    text-shadow: 0 0 2px #000, 0 0 5px #FFAA00, 0 0 15px #FFAA00;
  }

  66% {
    text-shadow: 0 0 2px #000, 0 0 5px #FFAA00, 0 0 20px #fcbc31, 0 0 20px #ddb254;
  }

  100% {
    text-shadow: 0 0 2px #000, 0 0 5px #FFAA00, 0 0 15px #FFAA00;
  }
}

.congrats {
  background: linear-gradient(152.62deg, #B66AFF 2.24%, #4A068B 97.58%);
  border: 2px solid #FCEE21;
  border-radius: 25px;
}

/* Example of improving text contrast */
.text-contrast-light {
  color: #B0B0B0; /* Lighter gray for better readability against dark backgrounds */
}

.text-contrast-dark {
  color: #FFFFFF; /* White for maximum contrast against dark backgrounds */
}

.border-contrast {
  border-color: #5A5A5A; /* Darker border for improved visibility */
}

.bg-black-contrast {
  background-color: #1A1A1A; /* Slightly lighter black to reduce harshness */
}