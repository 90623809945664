.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header-gradient {
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 383.59%);
}

.hero-box-shadow {
  filter: drop-shadow(-10px 5px 10px rgba(255, 221, 21, 0.5)) drop-shadow(10px -10px 10px rgba(255, 221, 21, 0.5));
}

.game-image {
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
  width: 200px;
  height: 200px;
}

.slick-slide.slick-center .game-image {
  width: 300px;
  height: 300px;
}

.end-image {
  align-items: flex-end;
  height: 300px;
}

.ourPlatformDropShadow {
  box-shadow: -6px 8px 30px 0px #FFDD1538;
  box-shadow: 6px 4px 30px 0px #FFDD155E;
}

@media (max-width: 575px) {
  .game-image {
    width: 100px;
    height: 100px;
  }

  .slick-slide.slick-center .game-image {
    width: 200px;
    height: 200px;
  }

  .end-image {
    height: 200px;
  }

}


.policy h1 {
  margin-bottom: 1.75rem !important;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  text-align: start;
  color: white;
}


.policy p,
li {
  font-size: 16px;
}

@media (max-width: 768px) {

  .policy h1 {
    font-size: 24px;
    line-height: 25px;
  }

  .policy p,
  li {
    font-size: 14px;
  }
}

.terms ol {
  counter-reset: item;
}

/* Main list items */
.terms ol>li {
  counter-increment: item;
}

.terms ol>li::before {
  content: counters(item, ".") " ";
  font-weight: bold;
  padding-right: 0.41rem;
  font-size: 1.2rem;
}

/* Nested list items */
.terms ol>li>ol {
  counter-reset: sub-item;
}

.terms ol>li>ol>li {
  counter-increment: sub-item;
}

.terms ol>li>ol>li::before {
  content: counters(item, ".") "." counters(sub-item, ".") " ";
}

.custom-slick-slider .slick-dots li button:before {
  color: #fee844;
}

.custom-slick-slider .slick-dots li.slick-active button:before {
  color: #fee844;
}

.infinite-scroll {
  position: relative;
  overflow: hidden;
  height: 367px;
  border-radius: 20px;
}

.infinite-scroll-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  animation: scroll 10s linear infinite;
}

.winner-email {
  width: 165px;
  text-align: left;
}

.winner-image {
  width: 50px;
  height: 50px;
}

@keyframes scroll {
  0% {
    top: 0;
  }

  100% {
    top: -100%;
  }
}
@keyframes arrow_moving {
  from {
      left: -100%;
  }
  to {
      left: 100%;
  }
}
.game-card {
  width: 100%;
  max-width: 18.5rem;
  background-color: #1c1c1e; /* Dark background for the card */
  border-radius: 1.125rem;
  box-shadow: 0 4px 15px #ffdd15; /* Initial shadow for depth */
  overflow: hidden;
  padding: 0.75rem;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.game-card:hover {
  transform: scale(1.05); /* Slightly scales up the card */
  box-shadow: 0 4px 15px #ffdd15; /* Increased shadow on hover */
}
.slick-prev {
  left: 0px !important;

}
.slick-next{right: 0px !important;}
.game-card img {
  width: 100%;
  height: auto;
  border-radius: 1rem; /* Matches the rounded corners of the card */
}

.game-card .button {
  background-color: #ffdd15; /* Button background color */
  color: #000;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.2; /* Improve readability */
  padding: 8px 16px; /* Add padding for breathing room */
  border-radius: 12px; /* Subtle rounded corners */
  border: 2px solid #ffdd15; /* Border color matches the background */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  text-align: center;
  box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.3); /* Inner shadow for depth */
  transition: all 0.3s ease;
}

.game-card .button:hover {
  background-color: #e3a400; /* Darken button color on hover */
  box-shadow: 0 0 12px #ffdd15, inset 0 0 8px rgba(255, 255, 255, 0.4); /* Glow effect on hover */
}
.dhnzov:not(:last-of-type) {
  border-bottom: 1px solid #aeaeae !important;
}

.css-1cpa8wi {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  border-radius: 9999px;
  position: relative;
}

.css-194gi2f {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.92);
}

.css-yp6gff {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  border-radius: 0px 0px 10px 10px;
  background: rgba(162, 0, 255, 0.15);
  font-weight: 700;
}

.levelUp {
  background: linear-gradient(100.18deg, #c626ac 33.08%, #7626c6 92.22%);
}

.css-17ejtfq {
  display: grid;
  gap: 1.25rem;
  grid-template-columns: repeat(2, minmax(0px, 1fr));
  margin-bottom: 1rem;
}

.css-ct8v74 {
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 100%;
  line-height: 1.2;
  border-radius: 0.375rem;
  font-weight: 600;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: 1rem;
  padding-inline-start: 1rem;
  padding-inline-end: 1rem;
  background: #D6BCFA;
  color: #1A202C;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.newNav_progress__SFIF_ {
  position: relative;
  cursor: pointer;
}

.newNav_levelBar__dD693 {
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.44px;
  text-align: left;
  padding: 2px 6px;
  white-space: nowrap;
  border-radius: 4px;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%);
  background: #1a202c;
  border: 1px solid #7626c6;
  z-index: 2;
}

.newNav_textItself__xsBoP {
  position: relative;
  z-index: 2;
}

.newNav_progress__bar__iBAcL {
  border-radius: 4px;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(100.18deg, #c626ac 33.08%, #7626c6 92.22%);
}

.progressBar {
  background: linear-gradient(100.18deg, #c626ac 33.08%, #7626c6 92.22%);
  color: white;
}

.shadow-custom {
  box-shadow: 0px 0px 20px 0px #FFDD15;
  border: 2px solid #FFDD15;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.shadow-custom:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 30px 5px #FFDD15;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 20px 0px #FFDD15;
  }

  50% {
    box-shadow: 0 0 25px 5px #FFDD15;
  }

  100% {
    box-shadow: 0 0 20px 0px #FFDD15;
  }
}

.shadow-custom:hover {
  animation: pulse 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 180px;
  position: fixed;
  bottom: -85px;
  left: 45%;
  right: 50%;
  z-index: 30;
  animation: spin 1s linear infinite;
  transition: bottom 0.5s ease;
}

.spinner:hover {
  bottom: 10px;
}